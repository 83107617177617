.component_blocks {
  float: left;
  margin: 5px;
  border: solid 1px var(--diaryPage-color);
  padding: 10px;
  background-color: var(--main-color);
}

.tabBlock {
  width: 500px;
  height: 150px;
  background-color: var(--sub1-color);

  border: solid 1px var(--diaryPage-color);
  box-sizing: border-box;
  padding: 5px;
  overflow: hidden;
}

.componentEx {
  width: 100px;
  background-color: aqua;
}
