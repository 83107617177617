.diary_page {
  display: flex;
  flex-direction: column;
  width: var(--pageWidth-size); /*500px; 80vw;*/
  height: var(--pageHeight-size); /* 700px; 141vw;*/
  background-color: var(--diaryPage-color);
  box-sizing: border-box;
  /* overflow: hidden; */

  /* display: grid;
  place-items: center; */
}

.diary_page_div {
  width: 100%;
  height: fit-content;
}

.btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
}

.btn_group {
  width: 500px;
  display: flex;
  justify-content: space-between;
}

.button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}
