.BlockWrapper:focus-within {
  /* box-sizing: border-box; */
  outline: 2px solid var(--sub1-color);
}

.block_animation {
  transition: all ease 2s 0s;
}

.tableRow {
  box-sizing: border-box;
  border: 2px solid black;
}

.tableCol {
  border: 1px solid;
  padding: 10px 5px;
}
